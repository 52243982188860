.toggleSwitch {
    height: 0;
    width: 0;
    visibility: hidden;
}

.toggleLabel {
    cursor: pointer;
    text-indent: -9999px;
    width: 40px;
    height: 20px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
    margin-right: 10px;
}

.toggleLabel:after {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    width: 18px;
    height: 18px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
}

.toggleSwitch:checked + .toggleLabel {
    background: rgb(1, 104, 250);
}

.toggleSwitch:checked + .toggleLabel:after {
    left: calc(100% - 1px);
    transform: translateX(-100%);
}

.toggleLabel:active:after {
    width: 24px;
}
